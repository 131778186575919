import LoginRequireModal from "../modals/LoginRequireModal";
import { functions } from "../firebase";
import React, { useState, useEffect } from "react";

const SCREEN_INVEST_TYPE = 0;
const SCREEN_INVEST_RANK = 1;

export default function UITest() {
  const [screen, setScreen] = useState(SCREEN_INVEST_TYPE);

  const clickNext = () => {
    setScreen(screen + 1);
  }

  return (
    <div className="
    w-full
    h-screen
    flex
    flex-col
    bg-background2
    bg-cover	
    leading-normal
    tracking-normal
    items-center
    p-20">

      <div className="flex flex-row justify-center content-center items-center w-screen pl-5 pr-5">

        {
          screen === SCREEN_INVEST_TYPE ? (
            <div className="flex flex-col">
              <h1 className="text-white text-2xl mb-1 pl-5 md:pl-0">당신의 투자 유형은 ...</h1>
              <h1 className="text-white font-bold text-3xl mb-5 pl-5 md:pl-0">돌직구로켓</h1>
              <img src="/assets/images/tiger.gif" className="self-center" style={{ width: 200, height: 200 }} />

              <div className="flex flex-col w-96 mt-5 pl-5 pr-5 md:p-0">
                <h1 className="text-white text-xl mb-3">투자 능력 상세분석</h1>

                <div className="shadow bg-gray-300 mb-2">
                  <div className="bg-blue-500 text-xs leading-none py-1 text-center text-white" style={{ width: "45%" }}>참을성 45%</div>
                </div>

                <div className="shadow bg-gray-300 mb-2">
                  <div className="bg-teal-500 text-xs leading-none py-1 text-center text-white" style={{ width: "55%" }}>존버능력 55%</div>
                </div>

                <div className="shadow bg-gray-300 mb-2">
                  <div className="bg-orange-500 text-xs leading-none py-1 text-center text-white" style={{ width: "55%" }}>롱무새 55%</div>
                </div>

                <div className="shadow bg-gray-300">
                  <div className="bg-red-500 text-xs leading-none py-1 text-center text-white" style={{ width: "55%" }}>숏무새 55%</div>
                </div>
              </div>

              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 ml-5 mr-5 md:ml-0 md:mr-0" onClick={clickNext}>
                나의 투자 순위 확인하기
              </button>
            </div>
          ) : null
        }

        {
          screen === SCREEN_INVEST_RANK ? (
            <div className="flex flex-col">
              <h1 className="text-white text-2xl mb-1 pl-10">당신의 투자 순위는 ...</h1>
              <h1 className="text-white font-bold text-3xl mb-1 pl-10">전세계 0.5%</h1>
              <h1 className="text-white font-bold text-2xl mb-1 pl-10">한국 30% 입니다.</h1>
              {/* <h2 className="text-white font-bold text-2xl mb-5">당신은 투자의 신입니다.</h2> */}
              <img src="/assets/gifs/world.gif" className="self-center" style={{ width: 200, height: 200 }} />

              {/* select 3 button */}
              <div className="flex flex-row justify-center content-center items-center w-screen mt-5 pl-5 pr-5 md:p-0 mb-10">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-5 mr-5 md:ml-0 md:mr-0">
                  하루 한번 푸시 메시지 발송
                </button>

                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-5 mr-5 md:ml-0 md:mr-0">
                  하루 세번 푸시 메시지 발송
                </button>
              </div>

              <p className="pl-10 pr-10 text-white">하루마다 초대된 친구에게 게임 플레이 푸시 메시지가 발송됩니다! 게임을 완료하고 최종 순위를 겨루고 상품을 받아보세요!</p>

              <button className="bg-yellow-700 hover:bg-yellow-900 text-white font-bold py-2 px-4 rounded mt-10 ml-5 mr-5 md:ml-0 md:mr-0" onClick={clickNext}>
                방 만들고 카카오톡 친구 초대하기
              </button>
              <div className="flex flex-col w-96 mt-5">
                {/* <h1 className="text-white text-xl mb-3">상세 랭킹</h1>

                <div className="shadow w-full bg-gray-300 mb-2">
                  <div className="bg-blue-500 text-xs leading-none py-1 text-center text-white" style={{ width: "45%" }}>전세계 45%</div>
                </div> */}


              </div>
            </div>
          ) : null
        }

        {

        }

      </div>
    </div>
  );
}