import BitCoinDatas from "../datas/bitcoin_day.json";

const BUFFER_MAX_CANDLES = 100;

export default class ChartBufferManager {
  constructor(canvasWidth, canvasHeight, p5) {
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.p5 = p5;

    this.candleX = [];
    this.chartBuffer = this._createChartBuffer();

    this.tempLinearValue = 50;


    // 코인 데이터 가져오기 관련
    this.bitCoinDatas = BitCoinDatas;
    this.bitCoinCurrentBarIdx = 0;
  }

  getNextBarPrice = () => {
    let bar = this.bitCoinDatas[this.bitCoinCurrentBarIdx++];
    return bar.close;
  }

  getBuffer = () => {
    return this.chartBuffer;
  }

  appendTempLinearItem = () => {
    this.chartBuffer.shift();
    this.chartBuffer.push({
      xPos: this.candleX[this.candleX.length - 1],
      price: this.tempLinearValue,
      exists: true
    });
    this.tempLinearValue += 1;
  }

  appendItem = (price) => {
    this.chartBuffer.shift();

    //this.canvasHeight
    // latest candle 은 50% 높이에 위치
    // latest candle 을 기준으로 나머지 캔들 배치 
    // latest candle 을 기준으로 나머지 밸런싱
    for (let i = 0; i < this.chartBuffer.length; i++) {
      if (this.chartBuffer[i].exists) {
        const diffPercent = (this.chartBuffer[i].price - 20000) / 20000 * 100;

        const diffHeight = (this.canvasHeight / 2) + (diffPercent);
        // this.chartBuffer[i].price = diffHeight;
        this.chartBuffer[i].height = diffHeight;
      }
    }

    this.chartBuffer.push({
      height: this.canvasHeight / 2,
      price: price,
      exists: true
    });
  }

  getXCoords = () => {
    return this.candleX;
  }

  _createChartBuffer() {
    let chartBuffer = [];
    this.candleX = Float32Array.from({ length: BUFFER_MAX_CANDLES }, (_, i) => this.p5.map(i, 0, BUFFER_MAX_CANDLES, 0, this.canvasWidth));
    for (let i = 0; i < this.canvasWidth; i++) {
      chartBuffer.push({
        price: 0,
        height: 0,
        exists: false
      });
    }

    return chartBuffer;
  }

  add(data) {
    this.buffer.push(data);
    if (this.buffer.length > this.bufferSize) {
      this.buffer.shift();
    }
  }

  getBuffer() {
    return this.buffer;
  }
}