import { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MobileDetectModal from "../modals/MobileDetectModal";
import mixpanel from "mixpanel-browser";

export default function PracticeOnboarding() {
  useEffect(() => {
    mixpanel.track('PAGE_PracticeOnBoarding');

    // if (window.matchMedia('(display-mode: standalone)').matches) {
    //   alert("PWA");
    // } else {
    //   alert("NOT PWA");
    // }
  }, []);

  const onClickPractice = () => {
    mixpanel.track('Go_Practice');
    window.location.href = "/practice";

  }

  return (
    <div className="
      w-full
      h-screen
      flex
      flex-col
      bg-background
      leading-normal
      tracking-normal">

      {/* <Banner /> */}
      {/* <MobileView>
        <MobileDetectModal />
      </MobileView> */}

      <div className="flex flex-col justify-center items-center w-full h-full">

        <h1 className="font-serif text-white text-4xl mb-10">TradeQuizs.com</h1>
        <h2 className="text-white text-xl mb-1">Practice and test your trading skill</h2>
        <h2 className="text-white text-xl mb-10">+30000 traders practiced last month</h2>
        {/* <h1 className="font-serif text-white text-base mb-10">Created by Streamize.inc</h1> */}

        <button
          onClick={onClickPractice}
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-2.5 text-xl font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Start Investing Practice Game
        </button>


        <div>
          <p className="text-white text-sm mt-10 mb-2  ml-5 mr-5">
            {'*  It takes approximately 10 minutes to measure.'}
          </p>

          <p className="text-white text-sm ml-5 mr-5">
            {'*  After the measurement is completed, you can check your global investment ranking.'}
          </p>
        </div>
      </div>

      {/* <button
        type="button"
        className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        시작하기
      </button> */}

    </div>
  )
}