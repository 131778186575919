// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_v29y3wk8jcLiHlXPJ3J0SAmw6g03af0",
  authDomain: "invest-practice.firebaseapp.com",
  projectId: "invest-practice",
  storageBucket: "invest-practice.appspot.com",
  messagingSenderId: "1029112716832",
  appId: "1:1029112716832:web:9e2c0215fcf19838c8a645",
  measurementId: "G-VV7K32G2B5"
};

const app = initializeApp(firebaseConfig);

// Initialize the Firebase components
const auth = getAuth();
const analytics = getAnalytics(app);
const db = getFirestore();
const functions = getFunctions(app);

export {
  auth,
  analytics,
  db,
  functions
}