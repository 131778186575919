import bitChart from "../datas/bitcoin_chart.json";

export default class ChartDataController {
  constructor() {
    const chartIndex = Math.floor(Math.random() * 7000) + 100;
    const candleDatas = bitChart.splice(chartIndex);

    this.allChartDatas = candleDatas;

    let bars = [];
    for (let i = 0; i < 100; i++) {
      bars.push(this.allChartDatas[i]);
    }

    // 현재 bar 의 index
    this.currentBarIdx = 0;

    // 커서 이동을 호출한 횟수
    this.moveStepCount = 0;
  }

  getBarByIndex(idx) {
    console.log("getBarByIndex", this.currentBarIdx + idx)
    return this.allChartDatas[this.currentBarIdx + idx];
  }

  getBarsCurrentBarIdx() {
    let arr = [];
    for (let i = 0; i < this.currentBarIdx; i++) {
      arr.push(this.allChartDatas[i]);
    }

    return arr;
  }

  getBarIdx() {
    return this.currentBarIdx;
  }

  getBarWithMoveStep(barCount) {
    let bars = [];
    for (let i = 0; i < barCount; i++) {
      bars.push(this.allChartDatas[this.currentBarIdx++]);
    }

    this.moveStepCount++;

    return bars;
  }

  getLatestCandle() {
    return this.allChartDatas[this.currentBarIdx - 1];
  }

  getNextSignalFromBarIdx(idx) {
    for (let i = idx + 1; ; i++) {
      let isBuy = false;
      let isSell = false;
      let signal = this.allChartDatas[i];

      if (signal["Buy"]) {
        isBuy = true;
      }

      if (signal["Sell"]) {
        isSell = true;
      }

      if (signal["Minimal Buy"]) {
        isBuy = true;
      }

      if (signal["Minimal Sell"]) {
        isSell = true;
      }

      if (signal["Strong Buy"]) {
        isBuy = true;

      }

      if (signal["Strong Sell"]) {
        isSell = true;
      }

      if (signal["Minimal Strong Buy"]) {
        isBuy = true;
      }

      if (signal["Minimal Strong Sell"]) {
        isSell = true;
      }

      if (isBuy) {
        return signal;
      }

      if (isSell) {
        return signal;
      }
    }
  }

  isBuyOrSell(bar) {
    let text = ''
    if (bar["Buy"]) {
      text = "Buy";
      return {
        signal: "long",
        text
      }
    }

    if (bar["Sell"]) {
      text = "Sell";
      return {
        signal: "short",
        text
      }
    }

    if (bar["Minimal Buy"]) {
      text = "Minimal_Buy";
      return {
        signal: "long",
        text
      }
    }

    if (bar["Minimal Sell"]) {
      text = "Minimal_Sell";
      return {
        signal: "short",
        text
      }
    }

    if (bar["Strong Buy"]) {
      text = "Strong_Buy";
      return {
        signal: "long",
        text
      }
    }

    if (bar["Strong Sell"]) {
      text = "Strong_Sell";
      return {
        signal: "short",
        text
      }
    }

    if (bar["Minimal Strong Buy"]) {
      text = "Minimal_Strong_Buy";
      return {
        signal: "long",
        text
      }
    }

    if (bar["Minimal Strong Sell"]) {
      text = "Minimal_Strong_Sell";
      return {
        signal: "short",
        text
      }
    }

    return {
      signal: "none",
      text: ""
    }
  }

}