import React, { Component, createRef } from 'react';
import dataFeed from "../services/dataFeed";
import ChartDataController from "../utils/chartDataController";
import { collection, addDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import LoginRequireModal from '../modals/LoginRequireModal';
import mixpanel from 'mixpanel-browser';
import Loading from "../components/Loading";
import { addTradeHistory } from "../services/firebaseFunctions";
import lottie from "lottie-web";
import { BrowserView, MobileView } from "react-device-detect";
import AnimatedNumbers from "react-animated-numbers";



const GoodAnim = require("./animations/good.json");
const BadAnim = require("./animations/bad.json");

const IS_LOADING_NEXT_BARS = true;
let LOADING_NEXT_BARS_INTERVAL = 100;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const START_SEED_MONEY = 10000;

export default class NewPracticeChart extends Component {
  constructor(props) {
    super(props);

    // parse bar path
    const barPath = window.location.pathname.split("/")[3];
    const speedPath = window.location.pathname.split("/")[4];

    LOADING_NEXT_BARS_INTERVAL = parseInt(speedPath);

    this.chartDataController = new ChartDataController();

    this.bfAPI = new dataFeed({
      debug: false,
      onSubscribeBars: this.onSubscribeBars,
      onLatestBar: this.onLatestBar,
      chartDataController: this.chartDataController
    });

    this.widgetOptions = {
      container_id: "chart_container",
      datafeed: this.bfAPI,
      library_path: "/charting_library/",
      disabled_features: [],
      symbol: "BTCUSDT",
      interval: "15",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      allow_symbol_change: true,
      theme: "dark",
      debug: false,
      // autosize: true,
      // preset: "mobile",
      fullscreen: true,
      overrides: {
        // https://github.com/tradingview/charting_library/issues/5744
        // 차트 타입 설정
        "mainSeriesProperties.style": parseInt(barPath),
        // 차트 패널 백그라운드 색 설정
        "paneProperties.background": "#000000",
        "paneProperties.vertGridProperties.color": "#000000",
        "paneProperties.horzGridProperties.color": "#000000",
        'paneProperties.crossHairProperties.color': '#ffffff',
        'paneProperties.crossHairProperties.style': 1,
        "paneProperties.legendProperties.showLegend": false,
        "paneProperties.legendProperties.showStudyArguments": false,
        "paneProperties.legendProperties.showStudyTitles": false,
        "paneProperties.legendProperties.showStudyValues": false,
        "paneProperties.legendProperties.showSeriesTitle": false,
        "paneProperties.legendProperties.showSeriesOHLC": false,
      },
      disabled_features: [
        "header_symbol_search",
        "header_resolutions",
        "header_settings",
        "header_chart_type",
        "header_indicators",
        "header_compare",
        "header_undo_redo",
        "header_widget",
        "left_toolbar",
        "timeframes_toolbar",
        "context_menus",
        "legend_widget",
        "chart_crosshair_menu",
        "pane_context_menu",
        "scales_context_menu",
        "legend_context_menu",
        "symbol_info",
        "control_bar",
        "timeframes_toolbar",
        "legend_widget",
        'legend_context_menu',
        'scales_context_menu',
        'pane_context_menu'
      ]
    };

    this.onSubscribeBarsCallbackData = null;

    // 로티 애니메이션 컴퍼넌트 Ref
    this.lottieRef = createRef();

    // 현재 포지션에 해당하는 캔들의 데이터 오브젝트 
    this.enterPositionCandle = null;



    this.state = {
      loading: false,

      gameFinishLeftTime: 30,

      // 현재 무포인지 롱인지 숏인지 상태
      currentUserPosition: "none",
      currentPositionProfit: 0,
      currentSumProfit: 0,
      enterPositionPrice: null,
      tradingHistory: [],
      leftTurnCount: 10,
      mySeedMoney: START_SEED_MONEY,

      // 모바일 일때, 메뉴 각각 메뉴 눌렀을때 보여줄 컴포넌트 ( chart, trade )
      mobileMenu: "chart",

      // 게임 종료 여부
      isGameEnd: false
    }

    // 모든 턴마다의 거래 수익률을 저장하는 배열
    this.profitPercentList = [];

    // sound play from public folder
    this.clickSound = new Audio("/assets/sounds/button.mp3");
    this.winSound = new Audio("/assets/sounds/makemoney.mp3");
    this.loseSound = new Audio("/assets/sounds/losemoney.mp3");

    // 거래 action 히스토리 ( long, short, exit 이벤트마다 바로 기록 저장 )
    this.tradeUserActionHistory = [];

    // 동기화 관련 변수
    this.isGameFin = false;

    // 마지막에 DB 에 쓸 데이터
    this.docGame = null;

    // 게임 타이머 시작
    // this._startGameTimer();
  }

  _startGameTimer = () => {
    this.gameTimer = setInterval(() => {
      this.setState({
        gameFinishLeftTime: this.state.gameFinishLeftTime - 1
      });
    }, 1000);
  }

  onTouchStart = () => {
    console.log("onTouchStart")
    this._enterLongPosition();
  }

  onTouchEnd = () => {
    console.log("onTouchEnd")
    this._enterExitPosition();
  }

  _onLoginSuccess = () => {
    this.setState({
      loading: true
    }, async () => {
      const response = await addTradeHistory(this.docGame);
      console.log(response);

      if (response != null) {
        // response is doc id
        this.props.navigateResult(response);
      }
    });
  }

  onMobileChartMenuClick = () => {
    return this.setState({
      mobileMenu: "chart"
    });
  }

  onMobileTradeMenuClick = () => {
    return this.setState({
      mobileMenu: "trade"
    });
  }

  // not used
  onLatestBar = (bar) => {
  }

  _enterLongPosition = () => {
    this.clickSound.play();

    this.enterPositionCandle = this.chartDataController.getLatestCandle();
    this.tradeUserActionHistory.push({
      action: "long",
      time: this.enterPositionCandle.time,
      price: this.enterPositionCandle.close,
    });

    // if (this.state.currentUserPosition === "none") {
    //   this.enterPositionCandle = this.chartDataController.getLatestCandle();

    //   this.tradeUserActionHistory.push({
    //     action: "long",
    //     time: this.enterPositionCandle.time,
    //     price: this.enterPositionCandle.close,
    //   });

    //   this.setState({
    //     currentUserPosition: "long",
    //     enterPositionPrice: this.enterPositionCandle.close
    //   }, () => {
    //     this.getNextBars(true);
    //   });
    // }
  }

  _enterShortPosition = () => {
    this.tradeUserActionHistory.push({
      action: "short",
      time: this.enterPositionCandle.time,
      price: this.enterPositionCandle.close,
    });
  }

  _enterExitPosition = () => {
    this.tradeUserActionHistory.push({
      action: "exit",
      time: this.chartDataController.getLatestCandle().time,
      price: this.chartDataController.getLatestCandle().close,
    });
  }

  // 현재 시드에서 얼마나 까였나 기준으로 수익률 계산
  getInvestingRevenue = () => {
    return {
      persentRevenue: (this.state.mySeedMoney - START_SEED_MONEY) / START_SEED_MONEY * 100,
      moneyRevenue: this.state.mySeedMoney - START_SEED_MONEY
    }
  }

  onSubscribeBars = (callback) => {
    // callback => {
    //   symbolInfo,
    //   resolution,
    //   onRealtimeCallback,
    //   subscriberUID,
    //   onResetCacheNeededCallback
    // }

    this.onSubscribeBarsCallbackData = callback;
  }

  chartReady = () => {
    if (!this.tradingViewWidget) return;
    this.tradingViewWidget.onChartReady(() => {
      //this.tradingViewWidget.setChartType(4);
      // onclick chart

      if (IS_LOADING_NEXT_BARS)
        setInterval(() => {
          this.getNextBars(true)
        }, LOADING_NEXT_BARS_INTERVAL);

    });
  }

  componentDidMount() {
    this.tradingViewWidget = window.tvWidget = new window.TradingView.widget(
      this.widgetOptions
    );

    this.chartReady();
  }

  drawTradingLine = () => {
    if (!this.tradingViewWidget) return;

    // clear all drawing
    this.tradingViewWidget.chart().removeAllShapes();

    // for loop this.tradeActionHistory
    let profitArr = [];
    let mySeedMoney = START_SEED_MONEY;

    for (let i = 0; i < this.tradeUserActionHistory.length; i++) {
      const action = this.tradeUserActionHistory[i].action;
      const time = this.tradeUserActionHistory[i].time;
      const price = this.tradeUserActionHistory[i].price;

      let nextAction = null;
      let nextTime = null;
      let nextPrice = null;

      // check next i is exist
      if (i + 1 < this.tradeUserActionHistory.length) {
        nextAction = this.tradeUserActionHistory[i + 1].action;
        nextTime = this.tradeUserActionHistory[i + 1].time;
        nextPrice = this.tradeUserActionHistory[i + 1].price;
      } else {
        const latestCandle = this.chartDataController.getLatestCandle();
        nextAction = "wait";
        nextTime = latestCandle.time;
        nextPrice = latestCandle.close;
      }

      if (nextAction === "exit") {
        // 5 배율
        const profitExit = ((nextPrice - price) / price * 100) * 5;
        mySeedMoney += mySeedMoney * (profitExit / 100);
      }

      const profitPercent = (nextPrice - price) / price * 100;
      profitArr.push(profitPercent);



      this.tradingViewWidget.activeChart().createMultipointShape(
        [{ time: time, price: price }, { time: nextTime, price: nextPrice }],
        {
          shape: "trend_line",
          color: "red",
          overrides: {
            showLabel: true,
            textcolor: "#FFFFFF",
            linewidth: 5,
            bold: true,
            linecolor: profitPercent > 0 ? "green" : "red",
          }
        }
      )

      i = i + 1;
    }

    console.log("RESULT", mySeedMoney, this.state.mySeedMoney)

    if (mySeedMoney != this.state.mySeedMoney) {
      if (mySeedMoney > this.state.mySeedMoney) {
        this.winSound.play();
      } else {
        this.loseSound.play();
      }

      this.setState({
        mySeedMoney: mySeedMoney
      })
    }
  }

  // enterPosition : 포지션 시작으로 인해 nextBar 가 불린건지
  getNextBars = (enterPosition) => {
    let bars = this.chartDataController.getBarWithMoveStep(1);

    this.drawTradingLine();

    this.onSubscribeBarsCallbackData.onRealtimeCallback({
      ...bars[0],
      time: parseFloat(bars[0].time * 1000),
    });
  }

  renderBottomNav = () => {
    return (
      <div className="flex md:hidden lg:hidden w-full h-screen">

        <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">

          <div className="flex flex-col justify-between px-2">
            {
              (this.state.currentUserPosition == "long" || this.state.currentUserPosition == "short") && (
                <p className="font-bold font-mono">Current Position Profit : {this.state.currentPositionProfit.toFixed(2)}%</p>
              )
            }
            <p className="font-bold font-mono">Sum Trade Profit : {this.getInvestingRevenue()['persentRevenue'].toFixed(2)}%</p>
          </div>

          <div className="flex flex-row pl-2 pr-2 pt-1">
            {
              this.state.currentUserPosition === "none" ? (
                <div className="flex w-full text-center">
                  <button
                    onClick={this._enterLongPosition}
                    type="button"
                    style={{ backgroundColor: '#56BE7C' }}
                    className="w-full inline-flex items-center rounded-md border border-transparent px-6 py-1 font-bold text-white shadow-sm mr-2 justify-center"
                  >
                    <div>
                      Buy/Long
                    </div>
                  </button>

                  <button
                    onClick={this._enterShortPosition}
                    type="button"
                    style={{ backgroundColor: '#E75662' }}
                    className="w-full inline-flex items-center rounded-md border border-transparent px-6 py-1 font-bold text-white shadow-sm justify-center"
                  >
                    <div>
                      Sell/Short
                    </div>
                  </button>
                </div>
              ) : (
                <div className="flex w-full text-center">
                  <button
                    onClick={this._enterExitPosition}
                    type="button"
                    style={{ backgroundColor: '#56BE7C' }}
                    className="w-full inline-flex items-center rounded-md border border-transparent px-6 py-1 font-bold text-white shadow-sm mr-2 justify-center"
                  >
                    <div>
                      Exit Position
                    </div>
                  </button>
                </div>
              )
            }

          </div>

          <div className="pl-2 pr-2">
            <button
              onClick={() => this.getNextBars(false)}
              type="button"
              className="w-full inline-flex bg-indigo-500 items-center rounded-md border border-transparent px-2 py-1 font-bold text-white shadow-sm mr-2 mt-2 justify-center"
            >
              <div>
                Next Bar
              </div>
            </button>
          </div>

          <div id="tabs" className="flex justify-between">
            <a onClick={this.onMobileChartMenuClick} className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
              <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <path d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z" fill="currentColor" fillOpacity="0.1"></path>
                  <path d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z" fill="currentColor"></path>
                  <path d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z" stroke="currentColor" strokeWidth="2" fill="currentColor" fillOpacity="0.1"></path>
                  <rect fill="currentColor" transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) " x="17" y="10.3137085" width="30" height="2" rx="1"></rect>
                  <rect fill="currentColor" transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) " x="-3" y="10.3137085" width="30" height="2" rx="1"></rect>
                </g>
              </svg>
              <span className="tab tab-home block text-xs">Chart</span>
            </a>
            <a onClick={this.onMobileTradeMenuClick} className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
              <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <path d="M14.7118754,20.0876892 L8.03575361,20.0876892 C5.82661462,20.0876892 4.03575361,18.2968282 4.03575361,16.0876892 L4.03575361,12.031922 C4.03575361,8.1480343 6.79157254,4.90780265 10.4544842,4.15995321 C8.87553278,8.5612583 8.1226025,14.3600511 10.9452499,15.5413938 C13.710306,16.6986332 14.5947501,18.3118357 14.7118754,20.0876892 Z M14.2420017,23.8186831 C13.515543,27.1052019 12.7414284,30.2811559 18.0438552,31.7330419 L18.0438552,33.4450645 C18.0438552,35.6542035 16.2529942,37.4450645 14.0438552,37.4450645 L9.90612103,37.4450645 C6.14196811,37.4450645 3.09051926,34.3936157 3.09051926,30.6294627 L3.09051926,27.813861 C3.09051926,25.604722 4.88138026,23.813861 7.09051926,23.813861 L14.0438552,23.813861 C14.1102948,23.813861 14.1763561,23.8154808 14.2420017,23.8186831 Z M20.7553776,32.160536 C23.9336213,32.1190063 23.9061943,29.4103976 33.8698747,31.1666916 C34.7935223,31.3295026 35.9925894,31.0627305 37.3154077,30.4407183 C37.09778,34.8980343 33.4149547,38.4450645 28.9036761,38.4450645 C24.9909035,38.4450645 21.701346,35.7767637 20.7553776,32.160536 Z" fill="currentColor" opacity="0.1"></path>
                  <g transform="translate(2.000000, 3.000000)">
                    <path d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z" stroke="currentColor" strokeWidth="2"></path>
                    <path d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z" stroke="currentColor" strokeWidth="2"></path>
                    <path d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z" stroke="currentColor" strokeWidth="2"></path>
                    <path d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z" fill="currentColor" transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "></path>
                    <circle stroke="currentColor" strokeWidth="2" cx="27.5" cy="27.5" r="7.5"></circle>
                  </g>
                </g>
              </svg>
              <span className="tab tab-kategori block text-xs">Profits</span>
            </a>
          </div>
        </section>
      </div>
    )
  }

  render() {
    return (
      <div className="w-screen flex flex-col h-screen">

        <div className=" bg-stone-900 absolute top-0 w-screen p-3 flex flex-row">
          <div>
            <p className="text-white">나의 총 재산</p>
            <div className="flex flex-row">
              <p style={{ fontSize: 32, color: 'white' }}>
                $
              </p>
              <AnimatedNumbers
                animateToNumber={parseInt(this.state.mySeedMoney)}
                fontStyle={{ fontSize: 32, color: 'white' }}
                configs={(number, index) => {
                  return { mass: 1, tension: 500 * (index + 1), friction: 140 };
                }}
              ></AnimatedNumbers>
            </div>
          </div>

          {/* <div className="flex justify-center content-center items-center ml-10">
            <p className="text-white">남은시간 : {this.state.gameFinishLeftTime}초</p>
          </div> */}
        </div>

        <div id="chart_container" className="flex-1" />

        <div
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouchEnd}
          onMouseDown={this.onTouchStart}
          onMouseUp={this.onTouchEnd}
          class="fixed h-full w-full flex items-center justify-center" />

        {
          this.state.loading && (
            <Loading />
          )
        }

        {
          this.state.isGameEnd ? (
            <LoginRequireModal
              title={"결과 분석이 완료 되었습니다!"}
              content={"로그인후 바로 회원님의 세계랭킹을 확인하세요."}
              onLoginSuccess={this._onLoginSuccess}
            />
          ) : null
        }

        {/* {this.renderBottomNav()} */}

        {/* lottie animation center */}

      </div>
    )
  }
}