import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { collection, setDoc, addDoc, doc, Timestamp, onSnapshot } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  sendEmailVerification,
  getIdTokenResult,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function signup(email, password) {
    try {
      const cred = await createUserWithEmailAndPassword(auth, email, password)

      return cred.user
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        alert("이미 사용중인 이메일입니다.")
      }

      // console.log(error.code)

      return null
    }
  }

  async function makeSubscribe(stripePrice) {
    // https://firebase.google.com/docs/firestore/query-data/listen
    const checkoutColl = collection(
      db,
      "users",
      currentUser.uid,
      "checkout_sessions"
    )

    const docRef = await addDoc(checkoutColl, {
      price: stripePrice,
      success_url: window.location.origin,
      cancel_url: window.location.origin
    })

    onSnapshot(docRef, (doc) => {
      const { error, url } = doc.data();

      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  async function getCustomClaim() {
    await auth.currentUser.getIdToken(true)
    const idTokenResult = await getIdTokenResult(currentUser)
    return idTokenResult.claims
  }

  async function getRoles() {
    const claims = await getCustomClaim()
    return [claims.stripeRole]
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  async function googleLogin() {
    let provider = new GoogleAuthProvider()

    try {
      const results = await signInWithPopup(auth, provider)
      const credential = GoogleAuthProvider.credentialFromResult(results);
      const token = credential.accessToken;
      // The signed-in user info.
      return results.user;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
    }

  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      console.log("USER", user)
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    makeSubscribe,
    getCustomClaim,
    getRoles,
    googleLogin
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}