export default class TradingLevelNav {
  constructor(revenuePercent) {
    this.revenuePercent = revenuePercent;
  }

  // getCharacterName() {
  //   if (this.revenuePercent >= 20) {
  //     return "lion";
  //   } else if (this.revenuePercent >= 15) {
  //     return "tiger";
  //   } else if (this.revenuePercent >= 10) {
  //     return "leopard";
  //   } else if (this.revenuePercent >= 5) {
  //     return "buffreturn "ladybug"
  //   } else if (this.revenuePercent >= -20) {
  //     return "firefly";
  //   }
  // }
}

// 1위랑
// 마지막 단위랑 극단적으로 
// ===>