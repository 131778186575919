import { useEffect, useState } from "react"
import { collection, setDoc, addDoc, doc, Timestamp, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import mixpanel from "mixpanel-browser";

export default function PracticeResult() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [topPercent, setTopPercent] = useState(null);
  const [tradingHistory, setTradingHistory] = useState(null);

  useEffect(() => {
    const docId = window.location.pathname.split("/")[3];
    const docRef = doc(db, "tradingHistory", docId);

    mixpanel.track("PAGE_PracticeResult", {
      docId: docId
    });

    onSnapshot(docRef, (doc) => {
      if (!doc.exists()) {
        navigate("/");
      } else {
        const {
          persentRevenue,
          moneyRevenue,
          seedMoney,
          useIndicatorMACD,
          useIndicatorRSI,
          useIndicatorWAVE
        } = doc.data();

        const tempTopPercent = 100 - (parseFloat(persentRevenue) / 232 * 100);
        setTopPercent(tempTopPercent);
        setTradingHistory({
          persentRevenue,
          moneyRevenue,
          seedMoney,
          useIndicatorMACD,
          useIndicatorRSI,
          useIndicatorWAVE
        })
      }
    });
  }, []);

  const getMyRank = () => {
    const p = (100 - (parseFloat(tradingHistory.persentRevenue) / 232 * 100)) / 100
    return parseInt(p * 5000);
  }

  return (
    <div className="
w-full
h-screen
flex
flex-col
bg-cover	
bg-background
leading-normal
tracking-normal">

      <div className="flex-1 rounded-lg bg-white shadow-lg w-11/12 md:w-3/4 lg:w-1/2 mx-auto my-10 overflow-auto">
        <div className="flex flex-col w-full p-4 md:p-6 text-center">
          <div className="flex items-center justify-center mb-5">
            {
              topPercent !== null ?
                (
                  <div className="flex flex-row items-center">
                    <div className="flex flex-col items-center">
                      <h1 className="font-serif text-black text-2xl mb-3">TradeQuizs.com</h1>
                      <div className="text-3xl font-bold text-gray-800 font-serif mb-2">You are in the top</div>
                      <div className="text-6xl font-bold text-indigo-600">{topPercent.toFixed(2)}%</div>
                      {/* <div className="text-sm font-medium text-gray-400">out of 100</div> */}
                    </div>
                  </div>
                ) : null
            }
          </div>

          {
            tradingHistory !== null ?
              (
                <div>
                  <p className="font-serif font-bold text-gray-600">ROE {tradingHistory.persentRevenue}%</p>
                  <p className="font-serif font-bold text-gray-600">Revenue ${tradingHistory.moneyRevenue}</p>
                </div>
              ) : null
          }

          <p className="font-serif font-bold mt-10 text-xl">Global LeaderBoard</p>

          <table
            className="table-auto w-full text-left whitespace-no-wrap mt-4">
            <thead>
              <tr>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">Rank</th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Country</th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Nickname</th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Profit</th>
              </tr>

            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-3">1st</td>
                <td className="px-4 py-3">America</td>
                <td className="px-4 py-3">Trade10Y_22</td>
                <td className="px-4 py-3">232%</td>
              </tr>

              <tr>
                <td className="px-4 py-3">2st</td>
                <td className="px-4 py-3">Japan</td>
                <td className="px-4 py-3">投資の貴重な</td>
                <td className="px-4 py-3">190%</td>
              </tr>

              <tr>
                <td className="px-4 py-3">3st</td>
                <td className="px-4 py-3">America</td>
                <td className="px-4 py-3">WS_StreetGam</td>
                <td className="px-4 py-3">184%</td>
              </tr>

              {/* <tr>
                <td className="px-4 py-3">4st</td>
                <td className="px-4 py-3">China</td>
                <td className="px-4 py-3">股票杀手</td>
                <td className="px-4 py-3">171%</td>
              </tr>

              <tr>
                <td className="px-4 py-3">5st</td>
                <td className="px-4 py-3">Canada</td>
                <td className="px-4 py-3">Morgan_RESP</td>
                <td className="px-4 py-3">151%</td>
              </tr> */}

              {
                tradingHistory !== null ?
                  (
                    <tr>
                      <td className="px-4 py-3 font-bold">{getMyRank()}st</td>
                      <td className="px-4 py-3 font-bold">Korea</td>
                      <td className="px-4 py-3 font-bold">Your Rank</td>
                      <td className="px-4 py-3 font-bold">{tradingHistory.persentRevenue}%</td>
                    </tr>
                  ) : null
              }

            </tbody>

          </table>

        </div>

        <div className="flex flex-col mx-10 mt-5 mb-10">
          <h1 className="text-center font-serif text-3xl">Algorithm Recommended by Wall Street Workers WAVE.ALGO Indicator</h1>
          <iframe className="mb-2 w-full" width="100%" height="550" src="https://player.vimeo.com/video/751532226" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>
          <img className="mx-auto" src="/assets/images/wavealgo.png" alt="leaderboard" width="100%" />
          <button
            onClick={() => {
              mixpanel.track("Click_WaveAlgoSite");
              window.location.href = "https://wavealgo.com"
            }}
            type="button"
            className="mt-5 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-2.5 text-xl font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 self-center">
            Try it for free
          </button>


          <button
            onClick={() => {
              mixpanel.track("Click_PlayAgain");
              window.location.href = "/practice"
            }}
            type="button"
            className="mt-5 inline-flex items-center rounded border border-transparent bg-indigo-800 px-2.5 py-2.5 text-xl font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 self-center">
            Play TradeQuizs again!
          </button>
        </div>

      </div>

      {/* <div className="flex-1 mr-10 mt-10 mb-10">
        <h1 className="text-white text-5xl font-serif">WAVE.ALGO</h1>
        <img className="w-1/2 mx-auto mt-10" src="/assets/images/wavealgo.png" alt="leaderboard" />
      </div> */}

    </div>
  )

}