import React, { Component } from "react";
import ChartBufferManager from "./ChartBufferManager";
import Sketch from "react-p5";

const W = 680, H = 200; // dimensions of canvas
const time = 400; // number of x tick values
const step = W / time; // time step

let data = []; // to store number of infected people
let count = 0; // steps counter
let pos, fy, c, infected, colors, maxH, l, f, posx;
let isResizeCanvas = false;
let translateX = 0;
let chartBufferManager = null;

// ===== NEW
let chartBuffer = [];

export default class NewPractice extends Component {
  constructor(props) {
    super(props);
  }

  setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(
      canvasParentRef
    );
    p5.frameRate(this.fr);

    chartBufferManager = new ChartBufferManager(
      window.innerWidth,
      window.innerHeight,
      p5
    );

    // for (let i = 0; i < 5; i++) {
    //   chartBufferManager.appendItem(chartBufferManager.getNextBarPrice());
    // }
  };

  draw = p5 => {
    let buffer = chartBufferManager.getBuffer();
    let xCoords = chartBufferManager.getXCoords();
    for (let i = 0; i < buffer.length - 1; i++) {
      if (buffer[i].exists) {
        p5.strokeWeight(0.2);
        //p5.line(xCoords[i], window.innerHeight, xCoords[i], buffer[i].height);
        // console.log(buffer[i].height)

        p5.strokeWeight(2);
        //p5.stroke(colors[Math.floor(map(y1, H, 10, H, 0))]);
        p5.line(xCoords[i], buffer[i].height, xCoords[i + 1], buffer[i + 1].height);
      }
    }

    const close = chartBufferManager.getNextBarPrice();
    chartBufferManager.appendItem(close);
  };

  render() {
    return <Sketch setup={this.setup} draw={this.draw} />;
  }
}
