import { app } from "./firebase";
import { Routes, Route, Navigate } from "react-router-dom";
import Practice from "./pages/Practice";
import Practice2 from "./pages/Practice2";
import PracticeOnboarding from "./pages/PracticeOnboarding";
import PracticeResult from "./pages/PracticeResult";
import NewPractice from "./pages/NewPractice";
import PracticeQuizChart from "./components/PracticeQuizChart";
import UITest from "./pages/UITest";
import { AuthProvider, useAuth } from "./contexts/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/new" element={<NewPractice />} />
        <Route path="/" element={<PracticeOnboarding />} />
        <Route path="/uitest" element={<UITest />} />
        <Route path="/new/practice/:bar/:speed" element={<Practice gameType={"new"} />} />
        <Route path="/practice" element={<Practice gameType={"old"} />} />
        <Route path="/practice/real" element={<Practice2 />} />
        <Route path="/practice/result/:id" element={<PracticeResult />} />
        <Route path="/practice/quiz" element={<PracticeQuizChart />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
