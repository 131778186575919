import PracticeChart from "../components/PracticeChart";
import NewPracticeChart from "../components/NewPracticeChart";
import PracticeQuizChart from "../components/PracticeQuizChart";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import "./Practice.css";

import mixpanel from 'mixpanel-browser';

export default function Practice({ gameType }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const navigateResultPage = (docId) => {
    navigate("/practice/result/" + docId);
  }

  useEffect(() => {
    mixpanel.track('PAGE_Practice');
  }, []);

  return (
    <div className="w-full
    h-screen
    flex
    flex-col
    bg-background
    bg-cover	
    leading-normal
    tracking-normal">

      {/* <LoginRequireModal /> */}

      {/* {
        gameType === "new" ? (
          <NewPracticeChart
            currentUser={currentUser}
            navigateResult={navigateResultPage} />
        ) : (
          <PracticeChart
            currentUser={currentUser}
            navigateResult={navigateResultPage} />
        )
      } */}

      <PracticeQuizChart
        currentUser={currentUser}
        navigateResult={navigateResultPage} />

    </div>
  );
}